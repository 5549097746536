import {useContext} from 'react'

import SelectorContext from '../utilities/SelectorContext'
import DetailList from './DetailList'
import clusterGroups from '../constants/clusterGroups'

export default function TractDetails() {
  const {feature, selectedCluster, highlightedTract} =
    useContext(SelectorContext)
  if (Object.keys(highlightedTract).length > 0) {
    const selected = highlightedTract.properties.cluster
    const clusterGroup = clusterGroups[selected]
    return (
      <div className="mt-4">
        <div>
          <p className="text-muted text-uppercase mb-1">
            Census tract:{' '}
            <span className="fw-bold fs-5 text-dark">
              {highlightedTract.properties.name}{' '}
            </span>
          </p>
          <p
            className={`d-inline-block group-${selectedCluster}-border`}
            style={{fontSize: 14}}
          >
            <span className="fw-bold" style={{fontSize: 24}}>
              {clusterGroup.name}
            </span>
          </p>
          <p
            className="text-muted text-uppercase mb-1"
            style={{fontSize: 14, fontWeight: 'bold', color: '#000000'}}
          >
            {highlightedTract.properties.countyname},{' '}
            {highlightedTract.properties.state}
          </p>
        </div>
        <DetailList feature={highlightedTract.properties} />
        <a
          href="#built-capital"
          className="btn btn-outline-dark text-uppercase"
        >
          Learn More
        </a>
      </div>
    )
  }

  if (Object.keys(feature).length === 0 && selectedCluster === 0) {
    return (
      <div className="mt-4">
        <p style={{color: '#696969', fontSize: 20, fontWeight: 'bold'}}>
          Select one of our seven peer groups of rural census tracts to see
          which rural areas share strengths and the capacity for growth. Also,
          learn more about a place—including its size, type of government, and
          available support organizations—by clicking on a census tract.
        </p>
      </div>
    )
  }
  if (Object.keys(feature).length === 0 && selectedCluster > 0) {
    const clusterGroup = clusterGroups[selectedCluster]
    if (!clusterGroup) return null
    return (
      <div className="mt-4">
        <p
          className={`d-inline-block group-${selectedCluster}-border`}
          style={{fontSize: 20}}
        >
          <span className="fw-bold">{clusterGroup.name}</span>
        </p>
        <p style={{fontSize: 14}}>{clusterGroup.description}</p>
        <p style={{fontSize: 14}}>
          <span className="fw-bold">Assets: </span>
          {clusterGroup.assets}
        </p>
        <p style={{fontSize: 14}}>
          <span className="fw-bold">Challenges: </span>
          {clusterGroup.challenges}
        </p>
        <a
          href="#built-capital"
          className="btn btn-outline-dark text-uppercase"
        >
          Learn More
        </a>
      </div>
    )
  }

  const clusterGroup = clusterGroups[selectedCluster]
  if (!clusterGroup) return null
  return (
    <div className="mt-4">
      <div>
        <p className="text-muted text-uppercase mb-1">
          Census tract:{' '}
          <span className="fw-bold fs-5 text-dark">
            {feature.properties.name}{' '}
          </span>
        </p>
        <p
          className={`d-inline-block group-${selectedCluster}-border`}
          style={{fontSize: 14}}
        >
          <span className="fw-bold" style={{fontSize: 24}}>
            {clusterGroup.name}
          </span>
        </p>
        <p
          className="text-muted text-uppercase mb-1"
          style={{fontSize: 14, fontWeight: 'bold', color: '#000000'}}
        >
          {feature.properties.countyname}, {feature.properties.state}
        </p>
      </div>
      <DetailList feature={feature.properties} />
      <a href="#built-capital" className="btn btn-outline-dark text-uppercase">
        Learn More
      </a>
    </div>
  )
}
