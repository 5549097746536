import React from 'react'
import ReactDOM from 'react-dom'
import {renderRoutes} from 'react-router-config'
import {BrowserRouter as Router} from 'react-router-dom'

import routes from './routes.js'
import '@fontsource/lato/900.css' // h1 font
import '@fontsource/lato/700.css' // h1 mobile font
import '@fontsource/lato/300.css' // h2-h6 font
import '@fontsource/lato/400.css' // Body font
import '@fontsource/lato/400-italic.css' // Italic variant

import './styles/globals.scss'

ReactDOM.render(
  <React.StrictMode>
    <Router>{renderRoutes(routes)}</Router>
  </React.StrictMode>,
  document.getElementById('root'),
)
