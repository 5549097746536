import {createContext, useState} from 'react'

const OrderByContext = createContext({})

export default OrderByContext

export function OrderByContextProvider({children}) {
  const [orderBy, setOrderBy] = useState('best')

  return (
    <OrderByContext.Provider
      value={{
        orderBy,
        setOrderBy,
      }}
    >
      {children}
    </OrderByContext.Provider>
  )
}
