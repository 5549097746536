const clusterGroups = [
  {
    name: 'All peer groups',
  },
  {
    name: 'Accessible, Energy-Rich Hubs',
    n: 2420,
    description:
      'One of two groups with relatively high access to coal mines and oil and natural gas wells, this group is also strong in financial and built capital. Tribal land covers 1 percent of an average tract in this group.',
    assets:
      'This group has the largest number of bank deposits per 1,000 people, the best physical proximity to banks and credit unions, and the best physical access to highway exits and emergency operations centers. Tracts in this group also have the greatest religious diversity and the second-best access to broadband and health care facilities.',
    challenges:
      'These tracts receive the lowest amount of investment from community development financial institutions per 1,000 people, and the population is largely homogeneous across race, ethnicity, and language.',
  },
  {
    name: 'High-Employment Agricultural Areas',
    n: 2252,
    description:
      'This group consists of farming tracts with high employment rates and strong access to health supports. Tribal land covers about 2 percent of an average tract in this group.',
    assets:
      'This group has the highest average share of land area covered in crops (63 percent), the highest labor force participation rate, and the highest average census participation rate. These tracts also score well across two health measures—nursing home capacity and health insurance coverage rate.',
    challenges:
      'The social welfare organizations in these tracts have the lowest financial capacity among all the groups, and these tracts have the worst access to child care centers.',
  },
  {
    name: 'Centers of Wealth and Health',
    n: 2232,
    description:
      'With mostly high scores on measures of income, education, and health, these tracts offer residents ample supports to help them thrive. An average tract in this group is 3 percent tribal land.',
    assets:
      'These tracts are strongest across the largest number of measures, including financial measures (highest median home value, median income, and federal investments), health measures (highest life expectancy, best access to health care professionals, and highest health insurance coverage), and educational attainment. This group also scores well in other quality-of-life measures: it has the best access to home broadband and cellular data, as well as libraries; the best air quality; the highest voter participation rate and level of political competition; and the shortest average distance to transportation stations such as bus terminals and airports.',
    challenges:
      'This group does not have any notable challenges, with scores around the average across every other measure.',
  },
  {
    name: 'Diverse, Institution-Rich Hubs',
    n: 1894,
    description:
      'Racially and ethnically diverse, these tracts have strong access to community institutions and health facilities, as well as the greatest amount of community development investment. An average tract in this group is around 10 percent tribal land, the second-highest average of any group.',
    assets:
      'This group has the best access to convention centers, colleges, schools, fire stations, emergency medical services, and health care facilities. Additionally, this group has the second-highest racial and ethnic diversity, the largest number of places of worship per 1,000 people, the largest number of hospital beds per 1,000 people, and the greatest amount of investment from community development financial institutions per 1,000 people.',
    challenges:
      'These tracts have limited financial resources, low labor force participation rates, the lowest life expectancy, the worst air quality, and the lowest share of households with access to home broadband or a cellular data subscription.',
  },
  {
    name: 'Remote, Energy-Rich Tracts',
    n: 1867,
    description:
      'These tracts are relatively close, on average, to mining, oil, and natural gas resources. Tribal land covers around 11 percent of an average tract’s land area, the highest of any group.',
    assets:
      'In addition to having the closest average physical proximity to coal mines and oil and natural gas wells, these tracts score well in access to community institutions, with the second-highest access to emergency operations centers and convention centers and the second-highest number of places of worship per 1,000 people.',
    challenges:
      'This group scores poorly on some measures of social, political, and cultural capital, with the lowest capacity of cultural organizations, the worst access to civic and social organizations, the lowest level of political competition, the least religious diversity, and the lowest density of historic properties. Other challenges include longer commute times and limited access to transportation, banking capacity, and health care professionals.',
  },
  {
    name: 'Diverse, Outlying Tracts',
    n: 1786,
    description:
      'Far from many resources, these remote tracts are rich in racial, ethnic, and linguistic diversity. An average tract in this group is around 6 percent tribal land.',
    assets:
      'Besides having the highest racial, ethnic, and linguistic diversity, this group has the second-highest level of federal investments and bank deposits per 1,000 people. The group also has the fewest workers commuting long distances and the second-shortest average distance to transportation stations.',
    challenges:
      'These tracts tend to be far from many resources, scoring worst in access to emergency operations centers, highway exits, convention centers, colleges, and farmers’ markets. This group also has the lowest average educational attainment and the lowest health insurance coverage and voter participation rates.',
  },
  {
    name: 'Remote Recreational and Cultural Areas',
    n: 597,
    description:
      'Covered by parks, this group has high cultural capacity and includes the fewest number of tracts, about 5 percent of all rural tracts. Tribal land covers around 1 percent of an average tract in this group.',
    assets:
      'Local, state, and national parks cover more than three-quarters of an average tract in this group, the highest share of any group. And these tracts have by far the highest capacity of cultural organizations and the highest financial capacity per 1,000 people of social welfare organizations. This group also has the highest density of historic properties and the best access to child care centers.',
    challenges:
      'This group lacks access to many services; it has the lowest proximity to fire stations and emergency medical services, banks and credit unions, health care facilities, public libraries, and schools. This group also has the second-lowest access to transportation stations and terminals, the lowest census participation rate, and the fewest hospital and nursing home beds per capita.',
  },
]
export default clusterGroups
