const data = [
  {
    name: 'Rural Average',
    cluster: 'NA',
    cell: 0.995,
    highway_dist: 35417.809,
    eoc_dist: 13410.229,
    intermodal_dist: 26992.91,
    fireems_dist: 5114.151,
    pct_kitchen: 0.99,
    pct_short_commute: 0.713,
    pct_broadband: 0.716,
    subsidizedproportion: 0.005,
    hist_prop: 0.415,
    cul_emp: 0.018,
    div_language: 0.131,
    div_race_ethnicity: 0.266,
    div_religion: 0.723,
    rev_artorg: 158831.547,
    distance_convention: 63767.34,
    deposit_per1k: 21504.59,
    cdfi_per1k: 85773.266,
    median_housingvalue: 141813.397,
    median_income: 25474.761,
    distance_bank: 5682.211,
    expectancy: 77.468,
    educ_attainment: 0.859,
    labor_rate: 0.733,
    emp_pop_ratio: 0.535,
    pct_insured: 0.889,
    healthprof_rate: 1.131,
    college_distance: 25816.328,
    farmersmarket_distance: 16106.8,
    hcfacilities_distance: 7417.117,
    childcare_access: 8.111,
    nursinghome_access: 1.056,
    hospital_beds: 2.908,
    easement_coverage: 0.011,
    avgpm25: 7.337,
    cropproportionarea: 0.185,
    lake_river_coverage: 0.000074063,
    distance_coal: 312239.969,
    distance_oilgas: 116313.859,
    park_coverage: 0.052,
    voter_turnout: 0.561,
    pol_competition: 0.36,
    censuspart_2010: 0.725,
    gov_emp: 0.053,
    distance_library: 6224.109,
    distance_school: 4735.433,
    avgrev_501c4: 1057.782,
    worship_per1k: 0.19,
    social_per_1k: 0.129,
    fed_investments_percapita: 2062808.125,
    tribal_coverage: 0.048,
    ruralnonpop: 'NA',
  },
  {
    name: 'Accessible, Energy-Rich Hubs',
    cluster: 1,
    cell: 0.99723965,
    highway_dist: 27564.594,
    eoc_dist: 10660.188,
    intermodal_dist: 24455.674,
    fireems_dist: 4645.5103,
    pct_kitchen: 0.98928636,
    pct_short_commute: 0.71868688,
    pct_broadband: 0.74033123,
    subsidizedproportion: 0.004910611,
    hist_prop: 0.37250641,
    cul_emp: 0.015237827,
    div_language: 0.067921631,
    div_race_ethnicity: 0.1483148,
    div_religion: 0.80200368,
    rev_artorg: 133284.16,
    distance_convention: 66724.75,
    deposit_per1k: 23534.658,
    cdfi_per1k: 30402.785,
    median_housingvalue: 129615.4545,
    median_income: 26233.28719,
    distance_bank: 4716.3882,
    expectancy: 77.360825,
    educ_attainment: 0.88942248,
    labor_rate: 0.75195557,
    emp_pop_ratio: 0.55209237,
    pct_insured: 0.90869266,
    healthprof_rate: 1.126009,
    college_distance: 22631.65,
    farmersmarket_distance: 11700.197,
    hcfacilities_distance: 6659.0273,
    childcare_access: 7.2826271,
    nursinghome_access: 1.1048213,
    hospital_beds: 3.5075498,
    easement_coverage: 0.006817957,
    avgpm25: 7.4610252,
    cropproportionarea: 0.10931136,
    lake_river_coverage: 0.0000883071,
    distance_coal: 191128.31,
    distance_oilgas: 52759.18,
    park_coverage: 0.022815306,
    voter_turnout: 0.57080221,
    pol_competition: 0.45564142,
    censuspart_2010: 0.75001764,
    gov_emp: 0.047564484,
    distance_library: 5764.9648,
    distance_school: 4354.7886,
    avgrev_501c4: 690.96033,
    worship_per1k: 0.17490196,
    social_per_1k: 0.17400432,
    fed_investments_percapita: 2005835.5,
    tribal_coverage: 0.014095599,
    ruralnonpop: 'NA',
  },
  {
    name: 'High-Employment Agricultural Areas',
    cluster: 2,
    cell: 0.99889213,
    highway_dist: 29882.461,
    eoc_dist: 11929.692,
    intermodal_dist: 29102.648,
    fireems_dist: 5613.3247,
    pct_kitchen: 0.9908241,
    pct_short_commute: 0.73239583,
    pct_broadband: 0.73924863,
    subsidizedproportion: 0.006370624,
    hist_prop: 0.36117521,
    cul_emp: 0.013665537,
    div_language: 0.086139612,
    div_race_ethnicity: 0.1651154,
    div_religion: 0.76534456,
    rev_artorg: 111453.81,
    distance_convention: 58483.836,
    deposit_per1k: 21956.441,
    cdfi_per1k: 128730.48,
    median_housingvalue: 120649.556,
    median_income: 28415.20471,
    distance_bank: 5472.4932,
    expectancy: 78.668686,
    educ_attainment: 0.8898688,
    labor_rate: 0.79158229,
    emp_pop_ratio: 0.59427178,
    pct_insured: 0.91898483,
    healthprof_rate: 1.0046222,
    college_distance: 26250.85,
    farmersmarket_distance: 14207.756,
    hcfacilities_distance: 7963.5601,
    childcare_access: 5.9976892,
    nursinghome_access: 1.4870492,
    hospital_beds: 2.366945,
    easement_coverage: 0.009327584,
    avgpm25: 7.5843024,
    cropproportionarea: 0.63117218,
    lake_river_coverage: 0.000190199,
    distance_coal: 290152.38,
    distance_oilgas: 97032.813,
    park_coverage: 0.005498907,
    voter_turnout: 0.5923394,
    pol_competition: 0.38338226,
    censuspart_2010: 0.77309114,
    gov_emp: 0.040954314,
    distance_library: 5797.8462,
    distance_school: 5200.73,
    avgrev_501c4: 583.10541,
    worship_per1k: 0.14103429,
    social_per_1k: 0.16955888,
    fed_investments_percapita: 2037349,
    tribal_coverage: 0.015010419,
    ruralnonpop: 'NA',
  },
  {
    name: 'Centers of Wealth and Health',
    cluster: 3,
    cell: 0.99403238,
    highway_dist: 40898.848,
    eoc_dist: 14418.985,
    intermodal_dist: 18185.824,
    fireems_dist: 4282.7754,
    pct_kitchen: 0.99075592,
    pct_short_commute: 0.72031105,
    pct_broadband: 0.79203719,
    subsidizedproportion: 0.004771575,
    hist_prop: 0.56007874,
    cul_emp: 0.026324404,
    div_language: 0.10359211,
    div_race_ethnicity: 0.18174183,
    div_religion: 0.7159639,
    rev_artorg: 240825.75,
    distance_convention: 66384.148,
    deposit_per1k: 22591.643,
    cdfi_per1k: 87908.719,
    median_housingvalue: 217001.1653,
    median_income: 29374.02061,
    distance_bank: 4980.2661,
    expectancy: 79.69445,
    educ_attainment: 0.92107594,
    labor_rate: 0.7667101,
    emp_pop_ratio: 0.56664032,
    pct_insured: 0.91983724,
    healthprof_rate: 1.6349423,
    college_distance: 24480.896,
    farmersmarket_distance: 9520.7129,
    hcfacilities_distance: 7555.291,
    childcare_access: 10.360837,
    nursinghome_access: 1.1536387,
    hospital_beds: 2.4147072,
    easement_coverage: 0.022550469,
    avgpm25: 6.0731058,
    cropproportionarea: 0.068622999,
    lake_river_coverage: 0.0000430726,
    distance_coal: 459450.44,
    distance_oilgas: 173836.86,
    park_coverage: 0.028030422,
    voter_turnout: 0.61894715,
    pol_competition: 0.16606228,
    censuspart_2010: 0.73055887,
    gov_emp: 0.055100597,
    distance_library: 5157.5986,
    distance_school: 4096.5186,
    avgrev_501c4: 1945.0851,
    worship_per1k: 0.16423962,
    social_per_1k: 0.15966567,
    fed_investments_percapita: 2382945.8,
    tribal_coverage: 0.026031462,
    ruralnonpop: 'NA',
  },
  {
    name: 'Diverse, Institution-Rich Hubs',
    cluster: 4,
    cell: 0.99633688,
    highway_dist: 27911.586,
    eoc_dist: 11810.641,
    intermodal_dist: 26814.99,
    fireems_dist: 4105.459,
    pct_kitchen: 0.99000889,
    pct_short_commute: 0.72005844,
    pct_broadband: 0.63689411,
    subsidizedproportion: 0.00570187,
    hist_prop: 0.41015357,
    cul_emp: 0.016863298,
    div_language: 0.10695167,
    div_race_ethnicity: 0.47005293,
    div_religion: 0.72252625,
    rev_artorg: 174764.78,
    distance_convention: 54948.793,
    deposit_per1k: 21508.244,
    cdfi_per1k: 194105.13,
    median_housingvalue: 101395.5116,
    median_income: 20658.46251,
    distance_bank: 5012.2427,
    expectancy: 74.899391,
    educ_attainment: 0.82078904,
    labor_rate: 0.67140579,
    emp_pop_ratio: 0.46942824,
    pct_insured: 0.86315078,
    healthprof_rate: 1.0119038,
    college_distance: 19320.957,
    farmersmarket_distance: 14264.711,
    hcfacilities_distance: 6087.6099,
    childcare_access: 9.8543367,
    nursinghome_access: 0.88426787,
    hospital_beds: 4.3794336,
    easement_coverage: 0.011322355,
    avgpm25: 8.218049,
    cropproportionarea: 0.10129524,
    lake_river_coverage: 0.00012422,
    distance_coal: 254827.56,
    distance_oilgas: 132097.59,
    park_coverage: 0.012193591,
    voter_turnout: 0.53763181,
    pol_competition: 0.23249748,
    censuspart_2010: 0.70234036,
    gov_emp: 0.062435865,
    distance_library: 5580.8477,
    distance_school: 3796.064,
    avgrev_501c4: 758.22321,
    worship_per1k: 0.35015818,
    social_per_1k: 0.081278205,
    fed_investments_percapita: 2021706.4,
    tribal_coverage: 0.10470022,
    ruralnonpop: 'NA',
  },
  {
    name: 'Remote, Energy-Rich Tracts',
    cluster: 5,
    cell: 0.99819589,
    highway_dist: 30450.408,
    eoc_dist: 11310.814,
    intermodal_dist: 41266.695,
    fireems_dist: 5699.8027,
    pct_kitchen: 0.99068892,
    pct_short_commute: 0.64695644,
    pct_broadband: 0.65951359,
    subsidizedproportion: 0.004571697,
    hist_prop: 0.28853777,
    cul_emp: 0.013968041,
    div_language: 0.084404878,
    div_race_ethnicity: 0.23168294,
    div_religion: 0.61422312,
    rev_artorg: 71780.742,
    distance_convention: 57344.082,
    deposit_per1k: 16464.143,
    cdfi_per1k: 44625.68,
    median_housingvalue: 104348.3664,
    median_income: 22316.2421,
    distance_bank: 6363.5244,
    expectancy: 75.531837,
    educ_attainment: 0.8135882,
    labor_rate: 0.67161173,
    emp_pop_ratio: 0.47948474,
    pct_insured: 0.86447179,
    healthprof_rate: 0.7801066,
    college_distance: 25909.68,
    farmersmarket_distance: 20749.199,
    hcfacilities_distance: 8149.3389,
    childcare_access: 6.4228425,
    nursinghome_access: 0.75877202,
    hospital_beds: 2.3687732,
    easement_coverage: 0.005691587,
    avgpm25: 8.1633158,
    cropproportionarea: 0.050324101,
    lake_river_coverage: 0.0000352039,
    distance_coal: 137706.34,
    distance_oilgas: 41210.617,
    park_coverage: 0.027967494,
    voter_turnout: 0.52367467,
    pol_competition: 0.58710814,
    censuspart_2010: 0.70453042,
    gov_emp: 0.054383531,
    distance_library: 7895.2959,
    distance_school: 5298.3857,
    avgrev_501c4: 735.47473,
    worship_per1k: 0.18329889,
    social_per_1k: 0.05880418,
    fed_investments_percapita: 1678074.1,
    tribal_coverage: 0.10660933,
    ruralnonpop: 'NA',
  },

  {
    name: 'Diverse, Outlying Tracts',
    cluster: 6,
    cell: 0.98429489,
    highway_dist: 56797.848,
    eoc_dist: 20220.316,
    intermodal_dist: 22623.037,
    fireems_dist: 5756.5962,
    pct_kitchen: 0.98591089,
    pct_short_commute: 0.74970472,
    pct_broadband: 0.69981068,
    subsidizedproportion: 0.004427651,
    hist_prop: 0.30186087,
    cul_emp: 0.020355728,
    div_language: 0.39866182,
    div_race_ethnicity: 0.50326157,
    div_religion: 0.69461823,
    rev_artorg: 126274.09,
    distance_convention: 77620.203,
    deposit_per1k: 23246.521,
    cdfi_per1k: 37226.727,
    median_housingvalue: 163212.4866,
    median_income: 24446.01008,
    distance_bank: 7283.2949,
    expectancy: 77.830368,
    educ_attainment: 0.78706074,
    labor_rate: 0.73142529,
    emp_pop_ratio: 0.53759396,
    pct_insured: 0.83543277,
    healthprof_rate: 1.1160473,
    college_distance: 35802.422,
    farmersmarket_distance: 29972.408,
    hcfacilities_distance: 7147.0581,
    childcare_access: 7.6329947,
    nursinghome_access: 0.99195933,
    hospital_beds: 2.8486707,
    easement_coverage: 0.00878378,
    avgpm25: 6.8875751,
    cropproportionarea: 0.15668851,
    lake_river_coverage: 0.0000224544,
    distance_coal: 554357.06,
    distance_oilgas: 215204,
    park_coverage: 0.015326025,
    voter_turnout: 0.49134016,
    pol_competition: 0.3407141,
    censuspart_2010: 0.68148577,
    gov_emp: 0.062207162,
    distance_library: 6686.2026,
    distance_school: 4960.98,
    avgrev_501c4: 626.94189,
    worship_per1k: 0.14962882,
    social_per_1k: 0.097006328,
    fed_investments_percapita: 2270016.3,
    tribal_coverage: 0.056331657,
    ruralnonpop: 'NA',
  },

  {
    name: 'Remote Recreational and Cultural Areas',
    cluster: 7,
    cell: 0.98887581,
    highway_dist: 43027.34,
    eoc_dist: 17638.256,
    intermodal_dist: 31245.514,
    fireems_dist: 7685.7651,
    pct_kitchen: 0.99206358,
    pct_short_commute: 0.6684103,
    pct_broadband: 0.72079754,
    subsidizedproportion: 0.004971336,
    hist_prop: 0.99095964,
    cul_emp: 0.026079776,
    div_language: 0.08550787,
    div_race_ethnicity: 0.18013892,
    div_religion: 0.69482291,
    rev_artorg: 453642.72,
    distance_convention: 68548.117,
    deposit_per1k: 18047.072,
    cdfi_per1k: 70424.297,
    median_housingvalue: 171362.4774,
    median_income: 24965.067,
    distance_bank: 8217.7031,
    expectancy: 78.172264,
    educ_attainment: 0.87535506,
    labor_rate: 0.69447976,
    emp_pop_ratio: 0.48789206,
    pct_insured: 0.89610755,
    healthprof_rate: 1.2740715,
    college_distance: 32519.602,
    farmersmarket_distance: 15601.52,
    hcfacilities_distance: 10648.167,
    childcare_access: 12.212429,
    nursinghome_access: 0.5394544,
    hospital_beds: 1.5519978,
    easement_coverage: 0.022023438,
    avgpm25: 6.6012554,
    cropproportionarea: 0.018441614,
    lake_river_coverage: 0.0000149862,
    distance_coal: 339761.16,
    distance_oilgas: 120564.26,
    park_coverage: 0.75698888,
    voter_turnout: 0.58617181,
    pol_competition: 0.35437793,
    censuspart_2010: 0.67723125,
    gov_emp: 0.060591135,
    distance_library: 9112.6504,
    distance_school: 7456.8203,
    avgrev_501c4: 4265.1675,
    worship_per1k: 0.15690912,
    social_per_1k: 0.13712645,
    fed_investments_percapita: 1906580.4,
    tribal_coverage: 0.011722985,
    ruralnonpop: 'NA',
  },
]
export default data
