import ContainerDimensions from 'react-container-dimensions'
import {round} from 'lodash'
import {useContext, useEffect, useState} from 'react'

import TopicChart from './TopicChart'
import ChartWrapper from './ChartWrapper'
import SelectorContext from '../utilities/SelectorContext'
import clusterData from '../constants/clusterData'

const barColors = {
  average: '#000',
  1: '#1696D2',
  2: '#55b748',
  3: '#FDBF11',
  4: '#EC008B',
  5: '#12719E',
  6: '#696969',
  7: '#73BFE2',
}

export default function Charts({group, openItemIndex}) {
  const {feature, selectedCluster} = useContext(SelectorContext)
  const [data, setData] = useState({})
  // Setting the data in a useEffect to ensure it rerenders with the new data
  useEffect(() => {
    const newData = {}
    function generateChartData(groupKey, subGroupKey, type, unit) {
      const data = []
      if (Object.keys(feature).length === 0 && selectedCluster === 0) {
        // Display all clusters
        clusterData.forEach((cluster, i) => {
          if (i === 0) return
          const value = cluster[subGroupKey]
          data.push({
            label: cluster.name,
            value: formatChartValue(value, type, subGroupKey, unit),
            color: barColors[i],
          })
        })
      } else if (Object.keys(feature).length === 0 && selectedCluster > 0) {
        // Display cluster and rural average
        // Cluster
        data.push({
          label: 'Group',
          value: formatChartValue(
            clusterData[selectedCluster][subGroupKey],
            type,
            subGroupKey,
            unit,
          ), // TODO: Get this from a constants file
          color: barColors[selectedCluster],
        })
        // Rural Average
        data.push({
          label: 'Rural Average',
          value: formatChartValue(
            clusterData[0][subGroupKey],
            type,
            subGroupKey,
            unit,
          ),
          color: barColors.average,
          overlapFontColor: '#fff',
        })
      } else if (Object.keys(feature).length > 0 && selectedCluster > 0) {
        // Display tract, cluster, and rural average
        let featureValue =
          feature.properties.groups && feature.properties.groups[groupKey]
            ? feature.properties.groups[groupKey][subGroupKey]
            : null
        data.push({
          label: 'Tract',
          value: formatChartValue(featureValue, type, subGroupKey, unit),
          color: barColors[selectedCluster],
        })
        // Cluster
        data.push({
          label: 'Group',
          value: formatChartValue(
            clusterData[selectedCluster][subGroupKey],
            type,
            subGroupKey,
            unit,
          ), // TODO: Get this from a constants file
          color: barColors[selectedCluster],
        })
        // Rural Average
        data.push({
          label: 'Rural Average',
          value: formatChartValue(
            clusterData[0][subGroupKey],
            type,
            subGroupKey,
            unit,
          ),
          color: barColors.average,
          fontColor: '#fff',
        })
      }
      return data
    }
    group.subGroups.forEach((subGroup) => {
      newData[subGroup.key] = generateChartData(
        group.key,
        subGroup.key,
        subGroup.type,
        subGroup.unit,
      )
    })
    setData(newData)
  }, [group, feature, selectedCluster])

  return (
    <ContainerDimensions>
      {({width}) => (
        <ChartWrapper
          width={width}
          openItemIndex={openItemIndex}
          selectedCluster={selectedCluster}
        >
          {group.subGroups &&
            group.subGroups.map((subGroup) => (
              <TopicChart
                key={subGroup.key}
                type={subGroup.type}
                groupKey={group.key}
                subGroupKey={subGroup.key}
                label={subGroup.label}
                data={data[subGroup.key]}
                subLabel={subGroup.subLabel}
                unit={subGroup.unit}
                direction={subGroup.direction}
              />
            ))}
        </ChartWrapper>
      )}
    </ContainerDimensions>
  )
}

function formatChartValue(value, type, subGroupKey, unit) {
  if (subGroupKey === 'lake_river_coverage') {
    return round(value * 100, 5)
  }
  if (subGroupKey === 'pol_competition') {
    return value * 100
  }
  if (unit === 'm' || unit === '$') {
    return round(value, 0)
  }
  if (value !== null && type === 'percent') {
    return parseFloat(parseFloat(value * 100).toFixed(1))
  }
  if (value !== null && !Number.isInteger(value)) {
    return parseFloat(parseFloat(value).toFixed(2))
  }
  return value
}
