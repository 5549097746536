import {orderBy} from 'lodash'
import {useContext, useEffect, useState} from 'react'
import ContainerDimensions from 'react-container-dimensions'
import {Bar, BarChart, Cell, Text, XAxis, YAxis} from 'recharts'
import OrderByContext from '../utilities/OrderByContext'

const RenderCustomizedLabel = (props) => {
  const {x, y, width, height, value, type, unit, containerWidth, subGroupKey} =
    props
  let xValue = x || 0
  let widthValue = width || 0
  const unitStr = unit || ''
  let valueString = value.toLocaleString()
  if (subGroupKey === 'lake_river_coverage') {
    valueString = parseFloat(valueString).toFixed(3) + '%'
  } else if (unitStr === '$') {
    valueString = unitStr + parseInt(value).toLocaleString()
  } else if (unitStr === 'm') {
    valueString = parseInt(value).toLocaleString() + unitStr
  } else if (type === 'percent') {
    valueString =
      parseFloat(valueString).toFixed(1) + (type === 'percent' ? '%' : unitStr)
  } else {
    valueString = parseFloat(valueString).toFixed(2) + unitStr
  }
  const valueStringWidth = valueString.length * 8 + 5
  const labels = 165 // pixels for the labels
  const rightSideOfChart = containerWidth - labels - widthValue // Estimated size for right side of bar
  // If the label is too long for the chart and too long for right side of chart,
  // display nothing
  if (valueStringWidth > widthValue && valueStringWidth > rightSideOfChart) {
    return null
  }
  const displayOnChart = valueStringWidth + 7 > rightSideOfChart
  const offset = displayOnChart ? 5 : valueStringWidth * -1
  let color = '#000'
  if (displayOnChart && props.color === '#000') {
    color = '#fff'
  }
  return (
    <text
      x={xValue + widthValue - offset}
      y={y + height - 7}
      textAnchor="end"
      fontSize={12}
      fill={color}
    >
      {valueString}
    </text>
  )
}

// Customized tick to move the label to the right and limit to 2 lines
const RenderCustomizedTick = ({y, payload}) => {
  return (
    // Using the g to move the text to the right side for alignment
    <g transform={`translate(${0},${y})`}>
      <Text
        maxLines={2}
        fontSize={14}
        width={165}
        fontWeight="bold"
        textAnchor="start"
        verticalAnchor="middle"
        className="recharts-cartesian-axis-tick-value"
      >
        {payload.value}
      </Text>
    </g>
  )
}

export default function TopicChart({
  type,
  label,
  subLabel,
  subGroupKey,
  unit,
  data,
  direction,
}) {
  const [time, setTime] = useState(0)
  const [finalData, setFinalData] = useState([])
  const [height, setHeight] = useState(300)
  const {orderBy: chartOrderBy} = useContext(OrderByContext)
  useEffect(() => {
    setHeight(300)
    setFinalData(orderBy(data, 'value', direction))
    // All charts use a single value for order by
    if (chartOrderBy === 'peer-group') {
      setFinalData(data)
    } else if (data.length === 2) {
      setHeight(125)
      setFinalData(data)
    } else if (data.length === 3) {
      setHeight(175)
      setFinalData(data)
    }
    setTime(Date.now())
  }, [data, chartOrderBy, direction])

  return (
    <div key={label} className="col-12 col-lg-6 col-md-6">
      <h3 className="fs-6 fw-bold mb-0 text-elipsis">{label}</h3>
      <p className="fst-italic text-elipsis" style={{fontSize: 14}}>
        {subLabel}
      </p>
      <ContainerDimensions>
        {({width}) => (
          <BarChart
            key={time} // Forcing rerender everytime the data changes by changing the key
            height={height}
            width={width - 20} // This helps add some space between
            data={finalData}
            layout="vertical"
            margin={{top: 5, right: 70, left: 30, bottom: 5}}
            barSize={25}
            animate={false}
          >
            <XAxis type="number" tick={false} axisLine={false} />
            <YAxis
              type="category"
              dataKey="label"
              axisLine={false}
              tickLine={false}
              tick={<RenderCustomizedTick />}
              min={0}
              width={120}
            />
            <Bar
              dataKey="value"
              label={
                <RenderCustomizedLabel
                  subGroupKey={subGroupKey}
                  type={type}
                  unit={unit}
                  containerWidth={width}
                />
              }
            >
              {finalData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color}></Cell>
              ))}
            </Bar>
          </BarChart>
        )}
      </ContainerDimensions>
    </div>
  )
}
