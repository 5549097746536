import {forEach} from 'lodash'

const TYPES = {
  NUMBER: 'number',
  STRING: 'string',
}

const groupProps = {
  built: {
    cell: TYPES.NUMBER,
    eoc_dist: TYPES.NUMBER,
    fireems_dist: TYPES.NUMBER,
    highway_dist: TYPES.NUMBER,
    intermodal_dist: TYPES.NUMBER,
    pct_broadband: TYPES.NUMBER,
    pct_kitchen: TYPES.NUMBER,
    pct_short_commute: TYPES.NUMBER,
    subsidizedproportion: TYPES.NUMBER,
  },
  cultural: {
    cul_emp: TYPES.NUMBER,
    distance_convention: TYPES.NUMBER,
    div_language: TYPES.NUMBER,
    div_race_ethnicity: TYPES.NUMBER,
    div_religion: TYPES.NUMBER,
    hist_prop: TYPES.NUMBER,
    rev_artorg: TYPES.NUMBER,
    tribal_coverage: TYPES.NUMBER,
  },
  financial: {
    cdfi_per1k: TYPES.NUMBER,
    deposit_per1k: TYPES.NUMBER,
    distance_bank: TYPES.NUMBER,
    fed_investments_percapita: TYPES.NUMBER,
    median_housingvalue: TYPES.NUMBER,
    median_income: TYPES.NUMBER,
  },
  human: {
    childcare_access: TYPES.NUMBER,
    college_distance: TYPES.NUMBER,
    educ_attainment: TYPES.NUMBER,
    emp_pop_ratio: TYPES.NUMBER,
    expectancy: TYPES.NUMBER,
    farmersmarket_distance: TYPES.NUMBER,
    hcfacilities_distance: TYPES.NUMBER,
    healthprof_rate: TYPES.NUMBER,
    hospital_beds: TYPES.NUMBER,
    labor_rate: TYPES.NUMBER,
    nursinghome_access: TYPES.NUMBER,
    pct_insured: TYPES.NUMBER,
  },
  natural: {
    avgpm25: TYPES.NUMBER,
    cropproportionarea: TYPES.NUMBER,
    distance_coal: TYPES.NUMBER,
    distance_oilgas: TYPES.NUMBER,
    easement_coverage: TYPES.NUMBER,
    lake_river_coverage: TYPES.NUMBER,
    park_coverage: TYPES.NUMBER,
  },
  political: {
    avgrev_501c4: TYPES.NUMBER,
    censuspart_2010: TYPES.NUMBER,
    gov_emp: TYPES.NUMBER,
    pol_competition: TYPES.NUMBER,
    voter_turnout: TYPES.NUMBER,
  },
  social: {
    distance_library: TYPES.NUMBER,
    distance_school: TYPES.NUMBER,
    social_per_1k: TYPES.NUMBER,
    worship_per1k: TYPES.NUMBER,
  },
}

const props = {
  name: TYPES.STRING,
  cluster: TYPES.NUMBER,
  geoid: TYPES.STRING,
  statefp: TYPES.STRING,
  countyfp: TYPES.STRING,
  statecountyfips: TYPES.STRING,
  state: TYPES.STRING,
  countyname: TYPES.STRING,
  primaryrucacode2010: TYPES.NUMBER,
  secondaryrucacode2010seee: TYPES.NUMBER,
  tractpopulation1418: TYPES.NUMBER,
  landareasquaremiles2010: TYPES.NUMBER,
  populationdensitypersquarem: TYPES.NUMBER,
  ruraltract: TYPES.STRING,
  muninumsummary: TYPES.STRING,
  ctyauth: TYPES.STRING,
  triballands: TYPES.STRING,
  execelect: TYPES.STRING,
  fedinvest: TYPES.STRING,
  rigoexist: TYPES.STRING,
  regcommission: TYPES.STRING,
  unitedway: TYPES.STRING,
  chamber: TYPES.STRING,
  chambernum: TYPES.NUMBER,
  rigoname: TYPES.STRING,
  muninum: TYPES.NUMBER,
  muni_name_1: TYPES.STRING,
  muni_name_2: TYPES.STRING,
  muni_name_3: TYPES.STRING,
  muni_name_4: TYPES.STRING,
  muni_name_5: TYPES.STRING,
  muni_name_6: TYPES.STRING,
  muni_name_7: TYPES.STRING,
  muni_name_8: TYPES.STRING,
  muni_name_9: TYPES.STRING,
  muni_name_10: TYPES.STRING,
  muni_name_11: TYPES.STRING,
  muni_name_12: TYPES.STRING,
  muni_name_13: TYPES.STRING,
  muni_name_14: TYPES.STRING,
  UnitedWayName1: TYPES.STRING,
  UnitedWayName2: TYPES.STRING,
  UnitedWayName3: TYPES.STRING,
  UnitedWayName4: TYPES.STRING,
  UnitedWayName5: TYPES.STRING,
  UnitedWayName6: TYPES.STRING,
  groups: groupProps,
}

export default function transformProperties(properties) {
  const result = {
    groups: {},
  }
  const lc_properties = lowercaseKeys(properties)
  forEach(props, (value, key) => {
    if (value === TYPES.NUMBER) {
      if (typeof lc_properties[key] === 'undefined') {
        return
      }
      result[key] = parseNumber(lc_properties[key])
    } else if (value === TYPES.STRING) {
      if (typeof lc_properties[key] === 'undefined') {
        return
      }
      result[key] = lc_properties[key]
    } else {
      forEach(value, (groups, k) => {
        forEach(groups, (v, groupKey) => {
          if (typeof lc_properties[groupKey] === 'undefined') {
            return
          }
          if (!result['groups'][k]) {
            result['groups'][k] = {
              [groupKey]: parseNumber(lc_properties[groupKey]),
            }
          } else {
            result['groups'][k][groupKey] = parseNumber(lc_properties[groupKey])
          }
        })
      })
    }
  })
  return result
}

function parseNumber(number) {
  if (typeof number === 'undefined') {
    return null
  }
  if (number.includes('.')) {
    return parseFloat(number)
  } else {
    return parseInt(number)
  }
}

// Convert keys in object to lowercase
export function lowercaseKeys(obj) {
  const result = {}
  Object.keys(obj).forEach((key) => {
    result[key.toLowerCase()] = obj[key]
  })
  return result
}
