import React from 'react'

import PeerExplainers from '../components/PeerExplainers'
import MapLayout from '../components/MapLayout'
import Accordion from '../components/Accordion'
import { SelectorContextProvider } from '../utilities/SelectorContext'
import clusterData from '../constants/clusterData'
import SocialButtons from '../components/SocialButtons'

const labels = clusterData
  .map((c) => c.name)
  .filter((label) => label !== 'Rural Average')
const PeerGroupLabels = labels.map((label, i) => (
  <div key={i} className="col-12 col-md-12 d-flex align-items-center">
    <span
      className={`group-${i + 1} me-1 me-md-2`}
      style={{ width: '12px', height: '12px', flex: '0 0 12px' }}
    />
    <p className="xs fw-bold m-0 lh-125">{label}</p>
  </div>
))
// Add additional legend item just for this map
PeerGroupLabels.push(
  <div
    key={labels.length}
    className="col-12 col-md-12 d-flex align-items-center"
  >
    <span
      className={`group-${labels.length + 1} me-1 me-md-2`}
      style={{ width: '12px', height: '12px', flex: '0 0 12px' }}
    />
    <p className="xs fw-bold m-0 lh-125">Urban tracts or uninhabited</p>
  </div>,
)

const textContainer = 'col-12 col-md-10 col-lg-8 col-xl-7 body-text'

export default function Home() {
  return (
    <SelectorContextProvider>
      <div>
        <div className="container-fluid">
          <div className="row" style={{ borderBottom: '1px solid #d2d2d2' }}>
            <div className="col-12">
              <a
                href="https://urban.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="logo"
                  src="/logo-badge-color.png"
                  className="d-inline-flex"
                  style={{
                    height: '34px',
                    width: '34px',
                    marginLeft: '26px',
                    marginTop: '12px',
                    marginBottom: '12px',
                  }}
                />
              </a>
              <h2 className="d-inline-flex ms-4" style={{ fontSize: '20px' }}>
                Features
                <div className="d-md-inline-flex d-none">
                  <span style={{ color: '#999', padding: '0 0.25em' }}>: :</span>
                  Reenvisioning Rural America
                </div>
              </h2>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center pt-7">
            <h1 className="text-center mb-4 fw-bold fs-1">
              Reenvisioning Rural America
            </h1>
          </div>
          <div className="row justify-content-center">
            <div className={textContainer}>
              <p className="fst-italic fs-5 text-center">
                How to Invest in the Strengths and Potential of Rural
                Communities
              </p>
              <p className="small text-muted text-center">September 21, 2021</p>
              <hr className="text-secondary mt-lg-5 mt-4 mb-lg-5 mb-4" />
              <SocialButtons />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className={textContainer}>
              <p>
                There is no single rural America. Its communities have diverse
                industries, cultures, and people. But definitions of these areas
                tend to lump them together under the catch-all of “not urban”
                and focus on what they are perceived as lacking. These practices
                result in the categorizing of rural communities by their
                challenges, not their strengths, and the obscuring of their
                varied realities.
              </p>
              <p>
                Rural communities are much more than what they need. Many are
                rich in resources, diversity, and potential. By better
                understanding these strengths, policymakers from the local to
                the federal level, practitioners, and investors can build on
                communities’ potential to better target investment and support.
              </p>
              <p>
                To help build a better foundation for leveraging rural assets,
                we studied populated census tracts across the US that are
                defined as “micropolitan,” “small town,” or “isolated rural” by
                the rural-urban commuting area codes from the US Department of
                Agriculture. Using characteristics of these rural census tracts,
                we constructed a typology that places each tract in one of seven
                peer groups according to their assets.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 col-xl-8">
              <div className="row align-items-center my-4">
                <div className="col-12 col-md-4 order-md-2">
                  <div className="row gx-1 gy-3 gy-md-2 justify-content-between align-center">
                    {PeerGroupLabels}
                  </div>
                </div>
                <div className="col-12 col-md-8 order-md-1">
                  <img
                    className="img-fluid"
                    src="/staticmap.jpg"
                    alt="Map of United States rural tracts, color coded into 7 peer groups"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className={textContainer}>
              <p className="mb-4 fst-italic" style={{ fontSize: '12px' }}>
                <span className="fw-bold">Note:</span> Rural areas with either
                zero population or zero housing units were excluded from our
                peer group analysis.
              </p>
              <p>
                The peer groups are based on measures of 50 assets organized
                across seven types of community capital, building on the{' '}
                <a
                  href="https://cdextlibrary.org/resource-library/promoting-community-vitality-sustainability-the-community-capitals-framework/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Community Capitals Framework
                </a>
                , which defines capitals as “resources that can be invested or
                tapped for the purpose of promoting the long-term well-being of
                communities.” We identified and categorized each rural census
                tract into groups of tracts with similar characteristics. From
                infrastructure to the natural environment, political
                participation to community life, these capital types can provide
                a window into how a community operates, where its strengths lie,
                and what challenges it faces.
              </p>
            </div>
          </div>
          <PeerExplainers />
          <div className="row justify-content-center">
            <div className={textContainer}>
              <p>
                Institutional contexts also shape rural realities. Local
                governments, other governing bodies, and support networks can
                all vary in structure and presence across rural areas. For each
                tract in the tool below, we summarize additional contextual
                factors.
              </p>
              <p>
                By illustrating the varied realities of rural communities, this
                dashboard allows local, state, and federal policymakers;
                practitioners; and investors to see the diversity of rural
                America and to better invest in its full potential.
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="col-12 col-md-8 mt-3 mt-xl-7">
            <h2 className="text-start">Explore Rural Peer Groups</h2>
          </div>
          <MapLayout />
          <Accordion />
        </div>
        <footer className="container pb-5 my-5">
          <div className="row justify-content-center">
            <div className={textContainer}>
              <h2 className="fw-light fs-3 text-uppercase">About the Data</h2>
              <p>
                Our study focuses on the 13,048 census tracts defined as
                “micropolitan,” “small town,” or “isolated rural” by the
                rural-urban commuting area codes from the US Department of
                Agriculture and excludes tracts without housing units or
                population. We included data in the typology only if they were
                available at no cost, recent (2010 or later), and reasonably
                reliable and accurate for rural areas. Ultimately, we selected
                50 measures from 25 data sources. We prioritized tract-level
                data, although only county or zip code data were available for
                some measures.
              </p>
              <p>
                We constructed distance-based measures by calculating the
                Euclidean distance from the population-weighted centroid to the
                nearest asset (all measured by point data). For tracts with one
                or more assets within their boundary, we took the average
                distance from all assets. For tracts without any assets, we
                calculated the distance to the nearest asset outside the tract.
                We used{' '}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.rgs.org/CMSPages/GetFile.aspx?nodeguid=018f17c3-a1af-4c72-abf2-4cb0614da9f8&lang=en-GB"
                >
                  Simpson’s Diversity Index
                </a>{' '}
                to measure racial and ethnic, linguistic, and religious
                diversity. The resulting score measures both the richness (i.e.,
                the number) and evenness (i.e., the distribution) of the groups.
                For example, given two communities with three groups, the
                Simpson’s Diversity Index score would tell us that community A
                (80 percent, 10 percent, 10 percent) is less diverse than
                community B (33 percent, 33 percent, 33 percent). One limitation
                of this measure is that it defines any tract that is heavily
                skewed toward a single group as having low diversity, regardless
                of the group. For example, a community that is 100 percent white
                non-Hispanic would receive the same low diversity score as a
                group that is 100 percent Black non-Hispanic (a score of 0
                diversity). The measure could more appropriately be called an
                integration index, but we use the common name of the measure
                instead.
              </p>
              <p>
                To prepare data for analysis, we imputed (estimated) values when
                data were missing. For most measures, the number of missing
                values was low (less than 1 percent of the data for each
                measure). In these instances, we assigned tracts with missing
                data the average for the county. For Hawaii and Alaska, the
                national average replaced missing data for cropland coverage and
                air quality. In Alaska, voter participation and political
                competition had to be imputed based on the state average because
                Alaska reports voting data by borough, whose boundaries do not
                align with those of tracts or counties. The measure of life
                expectancy required more extensive imputing for tracts in
                Wisconsin and Maine and 550 other tracts.
              </p>
              <p>
                We used min-max normalization to put all cleaned data on the
                same scale. Once data were cleaned, we used principal component
                analysis to reduce the dimensionality of our data and selected
                the first seven components to use in cluster analysis
                (describing about 80 percent of the variation in our data). We
                then used k-means cluster analysis to construct seven groups of
                tracts by minimizing differences within groups (i.e., minimizing
                within cluster sum of squares).
              </p>
              <p>
                Our study has several limitations. Our focus on assets resulted
                in the exclusion of many common, deficit-based measures. Also,
                we could not find good public data sources for some concepts we
                wanted to measure. This includes some measures of natural
                capital, including the potential for carbon capture, climate
                resilience, and renewable energy sources like solar and wind. In
                some intances, we used measures that were only the best proxy of
                the capital we were trying to describe (e.g., a measure of
                Euclidean distance instead of travel time and a measure of crop
                coverage instead of all types of agricultural land use). Many
                contextual measures may not provide a complete picture of the
                institutions important to rural community governance.
                Nongovernmental bodies in particular are highly varied or unique
                to communities and are thus not well-captured by national
                datasets. Finally, it is critical that users of the tool also
                incorporate local qualitative information as a complement to the
                data. Decisions should not be made on the basis of quantitative
                analysis alone.
              </p>
              <p>
                For more information about our measure definitions, analysis,
                robustness checks, and study limitiations, see the{' '}
                <a
                  href="https://www.urban.org/sites/default/files/2021/09/15/reenvisioning_rural_america_appendixes.pdf"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  technical appendixes
                </a>
                .
              </p>

              <p>You can also</p>
              <p>
                <a
                  href="https://datacatalog.urban.org/dataset/reenvisioning-rural-america-data"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="download-button"
                >
                  download the data
                  <img
                    alt="Download icon"
                    style={{
                      height: '14px',
                      marginLeft: '5px',
                      verticalAlign: 'middle',
                    }}
                    src="/download-arrow.png"
                  ></img>
                </a>
              </p>

              <h2 className="fw-light mt-5 fs-3 text-uppercase">
                Project Credits
              </h2>
              <p>
                Support for this feature was provided by the Robert Wood Johnson
                Foundation. We are grateful to them and to all our funders, who
                make it possible for Urban to advance its mission. The views
                expressed are those of the authors and do not necessarily
                reflect the views of the Robert Wood Johnson Foundation or the
                Urban Institute, its trustees, or its funders. Funders do not
                determine research findings or the insights and recommendations
                of Urban experts. More information on our funding principles is
                available{' '}
                <a
                  href="https://www.urban.org/aboutus/our-funding/funding-principles"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  here
                </a>
                . Read our terms of service{' '}
                <a
                  href="https://www.urban.org/terms-service"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  here
                </a>
                .
              </p>
              <p>
                Special thanks to our partners at US Department of Agriculture
                Rural Development and the Federal Reserve Board of Governors and
                to the members of our stakeholder advisory group and technical
                working group for their expert guidance and feedback on data,
                methodology, and tool development.
              </p>
              <p>
                We would also like to thank our Urban Institute colleague Aaron
                Williams for his guidance on principal component analysis and
                cluster analysis, as well as Christina Stacy for her
                methodological guidance during the design of this study and her
                detailed review and feedback on the technical appendixes.
              </p>
              <p>
                Finally, we appreciate all the organizations that collect,
                maintain, and share data on rural places, including the Homeland
                Infrastructure Foundation-Level Data, the National Center for
                Charitable Statistics, United Way, and the National Association
                of Counties. Without their careful stewardship and commitment to
                data sharing, this study would not have been possible.
              </p>
              <p>
                <span className="text-dark text-uppercase fw-bold me-1">
                  Research
                </span>{' '}
                <a
                  href="https://www.urban.org/author/corianne-payton-scally"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Corianne Payton Scally
                </a>
                ,{' '}
                <a
                  href="https://www.urban.org/author/amanda-gold"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Amanda Gold
                </a>
                ,{' '}
                <a
                  href="https://www.urban.org/author/yipeng-su"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Yipeng Su
                </a>
                ,{' '}
                <a
                  href="https://www.urban.org/author/jorge-morales-burnett"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Jorge Morales-Burnett
                </a>
                ,{' '}
                <a
                  href="https://www.urban.org/author/eric-burnstein"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Eric Burnstein
                </a>
                , and{' '}
                <a
                  href="https://www.urban.org/author/patrick-spauster"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Patrick Spauster
                </a>
              </p>
              <p>
                <span className="text-dark text-uppercase fw-bold me-1">
                  Design
                </span>{' '}
                Christina Baird
              </p>
              <p>
                <span className="text-dark text-uppercase fw-bold me-1">
                  Development
                </span>{' '}
                <a
                  href="https://mobility-labs.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Mobility Labs, Inc.
                </a>
              </p>
              <p>
                <span className="text-dark text-uppercase fw-bold me-1">
                  Editing
                </span>{' '}
                Meghan Ashford-Grooms
              </p>
              <p>
                <span className="text-dark text-uppercase fw-bold me-1">
                  Writing
                </span>{' '}
                Wesley Jenkins
              </p>
            </div>
          </div>
        </footer>
      </div>
    </SelectorContextProvider>
  )
}
