const states = {
  AL: {
    name: 'Alabama',
    min: [-88.473227, 30.223334],
    max: [-84.88908, 35.008028],
  },
  AK: {
    name: 'Alaska',
    min: [-179.148909, 51.214183],
    max: [-120.77847, 71.365162],
  },
  AZ: {
    name: 'Arizona',
    min: [-114.81651, 31.332177],
    max: [-109.045223, 37.00426],
  },
  AR: {
    name: 'Arkansas',
    min: [-94.617919, 33.004106],
    max: [-89.644395, 36.4996],
  },
  CA: {
    name: 'California',
    min: [-124.409591, 32.534156],
    max: [-114.131211, 42.009518],
  },
  CO: {
    name: 'Colorado',
    min: [-109.060253, 36.992426],
    max: [-102.041524, 41.003444],
  },
  CT: {
    name: 'Connecticut',
    min: [-73.727775, 40.980144],
    max: [-71.786994, 42.050587],
  },
  DE: {
    name: 'Delaware',
    min: [-75.788658, 38.451013],
    max: [-75.048939, 39.839007],
  },
  DC: {
    name: 'District Of Columbia',
    min: [-77.119759, 38.791645],
    max: [-76.909395, 38.99511],
  },
  FL: {
    name: 'Florida',
    min: [-87.634938, 24.523096],
    max: [-80.031362, 31.000888],
  },
  GA: {
    name: 'Georgia',
    min: [-85.605165, 30.357851],
    max: [-80.839729, 35.000659],
  },
  HI: {
    name: 'Hawaii',
    min: [-178.334698, 18.910361],
    max: [-154.806773, 28.402123],
  },
  ID: {
    name: 'Idaho',
    min: [-117.243027, 41.988057],
    max: [-111.043564, 49.001146],
  },
  IL: {
    name: 'Illinois',
    min: [-91.513079, 36.970298],
    max: [-87.494756, 42.508481],
  },
  IN: {
    name: 'Indiana',
    min: [-88.09776, 37.771742],
    max: [-84.784579, 41.760592],
  },
  IA: {
    name: 'Iowa',
    min: [-96.639704, 40.375501],
    max: [-90.140061, 43.501196],
  },
  KS: {
    name: 'Kansas',
    min: [-102.051744, 36.993016],
    max: [-94.588413, 40.003162],
  },
  KY: {
    name: 'Kentucky',
    min: [-89.571509, 36.497129],
    max: [-81.964971, 39.147458],
  },
  LA: {
    name: 'Louisiana',
    min: [-94.043147, 28.928609],
    max: [-88.817017, 33.019457],
  },
  ME: {
    name: 'Maine',
    min: [-71.083924, 42.977764],
    max: [-66.949895, 47.459686],
  },
  MD: {
    name: 'Maryland',
    min: [-79.487651, 37.911717],
    max: [-75.048939, 39.723043],
  },
  MA: {
    name: 'Massachusetts',
    min: [-73.508142, 41.237964],
    max: [-69.928393, 42.886589],
  },
  MI: {
    name: 'Michigan',
    min: [-90.418136, 41.696118],
    max: [-82.413474, 48.2388],
  },
  MN: {
    name: 'Minnesota',
    min: [-97.239209, 43.499356],
    max: [-89.491739, 49.384358],
  },
  MS: {
    name: 'Mississippi',
    min: [-91.655009, 30.173943],
    max: [-88.097888, 34.996052],
  },
  MO: {
    name: 'Missouri',
    min: [-95.774704, 35.995683],
    max: [-89.098843, 40.61364],
  },
  MT: {
    name: 'Montana',
    min: [-116.050003, 44.358221],
    max: [-104.039138, 49.00139],
  },
  NE: {
    name: 'Nebraska',
    min: [-104.053514, 39.999998],
    max: [-95.30829, 43.001708],
  },
  NV: {
    name: 'Nevada',
    min: [-120.005746, 35.001857],
    max: [-114.039648, 42.002207],
  },
  NH: {
    name: 'New Hampshire',
    min: [-72.557247, 42.69699],
    max: [-70.610621, 45.305476],
  },
  NJ: {
    name: 'New Jersey',
    min: [-75.559614, 38.928519],
    max: [-73.893979, 41.357423],
  },
  NM: {
    name: 'New Mexico',
    min: [-109.050173, 31.332301],
    max: [-103.001964, 37.000232],
  },
  NY: {
    name: 'New York',
    min: [-79.762152, 40.496103],
    max: [-71.856214, 45.01585],
  },
  NC: {
    name: 'North Carolina',
    min: [-84.321869, 33.842316],
    max: [-75.460621, 36.588117],
  },
  ND: {
    name: 'North Dakota',
    min: [-104.0489, 45.935054],
    max: [-96.554507, 49.000574],
  },
  OH: {
    name: 'Ohio',
    min: [-84.820159, 38.403202],
    max: [-80.518693, 41.977523],
  },
  OK: {
    name: 'Oklahoma',
    min: [-103.002565, 33.615833],
    max: [-94.430662, 37.002206],
  },
  OR: {
    name: 'Oregon',
    min: [-124.566244, 41.991794],
    max: [-116.463504, 46.292035],
  },
  PA: {
    name: 'Pennsylvania',
    min: [-80.519891, 39.7198],
    max: [-74.689516, 42.26986],
  },
  RI: {
    name: 'Rhode Island',
    min: [-71.862772, 41.146339],
    max: [-71.12057, 42.018798],
  },
  SC: {
    name: 'South Carolina',
    min: [-83.35391, 32.0346],
    max: [-78.54203, 35.215402],
  },
  SD: {
    name: 'South Dakota',
    min: [-104.057698, 42.479635],
    max: [-96.436589, 45.94545],
  },
  TN: {
    name: 'Tennessee',
    min: [-90.310298, 34.982972],
    max: [-81.6469, 36.678118],
  },
  TX: {
    name: 'Texas',
    min: [-106.645646, 25.837377],
    max: [-93.508292, 36.500704],
  },
  UT: {
    name: 'Utah',
    min: [-114.052962, 36.997968],
    max: [-109.041058, 42.001567],
  },
  VT: {
    name: 'Vermont',
    min: [-73.43774, 42.726853],
    max: [-71.464555, 45.016659],
  },
  VA: {
    name: 'Virginia',
    min: [-83.675395, 36.540738],
    max: [-75.242266, 39.466012],
  },
  WA: {
    name: 'Washington',
    min: [-124.763068, 45.543541],
    max: [-116.915989, 49.002494],
  },
  WV: {
    name: 'West Virginia',
    min: [-82.644739, 37.201483],
    max: [-77.719519, 40.638801],
  },
  WI: {
    name: 'Wisconsin',
    min: [-92.888114, 42.491983],
    max: [-86.805415, 47.080621],
  },
  WY: {
    name: 'Wyoming',
    min: [-111.056888, 40.994746],
    max: [-104.05216, 45.005904],
  },
}

export default states

export function getStateName(abbreviation) {
  if (!abbreviation) return null
  return states[abbreviation].name
}
