import classNames from 'classnames'
import {useContext} from 'react'
import {Dropdown} from 'react-dropdown-now'

import 'react-dropdown-now/style.css'

import clusterGroups from '../constants/clusterGroups'
import SelectorContext from '../utilities/SelectorContext'

export default function GroupSelector({classes}) {
  const {selectedCluster, setSelectedCluster, setFeature} =
    useContext(SelectorContext)
  const options = clusterGroups.map((c, i) => ({
    value: c.name,
    label: c.name,
    className: i > 0 ? `option-group-${i}` : '',
  }))

  function handleChange({value}) {
    const index = options.findIndex((option) => value === option.value)
    if (index === 0) {
      setFeature({})
    }
    setSelectedCluster(index)
  }

  const classnames = classNames(classes, `selected-group-${selectedCluster}`)

  return (
    <Dropdown
      className={classnames}
      options={options}
      value={options[selectedCluster]}
      onChange={handleChange}
      placeholder="Select an option"
    />
  )
}
