import {useContext} from 'react'
import OrderByContext from '../utilities/OrderByContext'
import SelectorContext from '../utilities/SelectorContext'

export default function OrderSelector() {
  const {orderBy, setOrderBy} = useContext(OrderByContext)
  const {selectedCluster} = useContext(SelectorContext)

  if (selectedCluster) {
    return null
  }

  return (
    <div>
      <span className="small text-muted d-inline-block me-2">Order By:</span>
      <div className="btn-group" role="group" aria-label="Toggle order">
        <input
          type="radio"
          className="btn-check"
          name="order-by"
          id="btn-best-order"
          autoComplete="off"
          checked={orderBy === 'best'}
          onChange={() => setOrderBy('best')}
        />
        <label
          className={`btn btn-sm ${
            orderBy === 'best' ? 'btn-dark' : 'btn-secondary'
          }`}
          style={{borderRadius: 0}}
          htmlFor="btn-best-order"
        >
          Best Value
        </label>

        <input
          type="radio"
          className="btn-check"
          name="order-by"
          id="btn-peer-group-order"
          autoComplete="off"
          checked={orderBy === 'peer-group'}
          onChange={() => setOrderBy('peer-group')}
        />
        <label
          className={`btn btn-sm ${
            orderBy === 'peer-group' ? 'btn-dark' : 'btn-secondary'
          }`}
          style={{borderRadius: 0}}
          htmlFor="btn-peer-group-order"
        >
          Peer Group
        </label>
      </div>
    </div>
  )
}
