import ContainerDimensions from 'react-container-dimensions'
import GroupSelector from './GroupSelector'
import MapSection from './MapSection'
import TractDetails from './TractDetails'

export default function MapLayout() {
  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-12">
          <ContainerDimensions>
            {({ width, height }) => (
              <div className="row my-4">
                <div className="col-12 d-block d-md-none mb-sm-5">
                  <GroupSelector classes="mt-md-5" />
                </div>
                <div className="col-12 col-md-8">
                  <MapSection width={width} />
                </div>
                <div className="col-12 col-md-4 map-sidebar">
                  <GroupSelector classes="mt-md-0 d-none d-md-block" />
                  <TractDetails />
                </div>
              </div>
            )}
          </ContainerDimensions>
        </div>
      </div>
    </div>
  )
}
