import classNames from 'classnames'
import {kebabCase} from 'lodash'

import GroupSelector from './GroupSelector'
import Charts from './Charts'
import OrderSelector from './OrderSelector'

export default function AccordionItem({
  group,
  openItemIndex,
  openItem,
  itemIndex,
}) {
  const classes = classNames('accordion-collapse', {
    collapse: openItemIndex !== itemIndex,
  })

  const buttonClasses = classNames('accordion-button', 'fs-5', 'px-0', {
    collapsed: openItemIndex !== itemIndex,
  })

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={kebabCase(group.label)}>
        <a
          className={buttonClasses}
          type="button"
          aria-expanded={openItemIndex === itemIndex ? 'true' : 'false'}
          aria-controls={`flush-collapse-${itemIndex}`}
          onClick={() => {
            openItem(itemIndex, group.label)
          }}
          href={`#${kebabCase(group.label)}`}
        >
          {group.label}
        </a>
      </h2>
      <div
        id={`flush-collapse-${itemIndex}`}
        className={classes}
        aria-labelledby={kebabCase(group.label)}
        data-bs-parent="#group-accordion"
      >
        <div className="accordion-body px-0 pt-2">
          <div className="row">
            <div className="col-12 col-md-6">
              <GroupSelector classes={'d-block'} />
            </div>
            <div className="col-12 col-md-6 text-start text-md-end mt-2 mt-md-0">
              <OrderSelector />
            </div>
          </div>
          <div className="row g-3 mt-3">
            {/* Do not render charts if they aren't visible */}
            {/* Passing in openItemIndex to this component to force rerender */}
            {openItemIndex === itemIndex ? (
              <Charts group={group} openItemIndex={openItemIndex} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
