import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import {useContext, useEffect, useRef, useState} from 'react'
import {GrPowerReset} from 'react-icons/gr'
import ReactMapboxGl, {GeoJSONLayer, ZoomControl} from 'react-mapbox-gl'
import states from '../constants/states'
import SelectorContext from '../utilities/SelectorContext'
import transformProperties from '../utilities/transformProperties'
import usePrevious from '../utilities/usePrevious'

mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
const MAP_STYLE_URI = process.env.REACT_APP_MAPBOX_STYLE_URI
const MAP_LAYER_ID = process.env.REACT_APP_MAPBOX_LAYER_ID

const Map = ReactMapboxGl({
  minZoom: 2.01,
  maxZoom: 7,
  scrollZoom: false,
  boxZoom: false,
  trackResize: true,
  accessToken: ACCESS_TOKEN,
})

export default function MapSection({width}) {
  const [map, setMap] = useState(null)
  const [center, setCenter] = useState({
    lat: 37.43414676256813,
    lng: -95.33528249286472,
  })
  const [bounds, setBounds] = useState([
    [-124.848974, 24.396308],
    [-66.885444, 49.384358],
  ])
  const [zoom, setZoom] = useState(3)
  const [sourceDataLoaded, setSourceDataLoaded] = useState(false)
  const {
    selectedCluster,
    setSelectedCluster,
    feature,
    setFeature,
    highlightedTract,
    setHighlightedTract,
  } = useContext(SelectorContext)
  const previousSelectedCluster = usePrevious(selectedCluster)
  const [zoomedIn, setZoomedIn] = useState(false)

  useEffect(() => {
    // HACK: This try catch is because in development when hotreloading, map is undefined
    try {
      if (sourceDataLoaded && map && selectedCluster > 0) {
        map.setFilter(MAP_LAYER_ID, [
          '==',
          'cluster',
          selectedCluster.toString(),
        ])
        map.resize()
      } else if (sourceDataLoaded && map && selectedCluster === 0) {
        map.setFilter(MAP_LAYER_ID, null)
        map.resize()
      }
    } catch (e) {
      console.log(e)
    }
    if (
      sourceDataLoaded &&
      selectedCluster > 0 &&
      previousSelectedCluster !== selectedCluster
    ) {
      setFeature({}) // Clear the feature when we change clusters
    }
  }, [
    map,
    selectedCluster,
    sourceDataLoaded,
    previousSelectedCluster,
    setFeature,
  ])

  const handleClick = (m, location) => {
    // If the user clicks on the map, we will get features at that point from our layer
    const features = m.queryRenderedFeatures(location.point, {
      layers: [MAP_LAYER_ID],
    })
    // The queryRenderedFeatures method returns an array of features which should just be a single feature in our case
    if (features.length > 0) {
      const f = features[0]
      f.properties = transformProperties(f.properties)
      const state = states[f.properties.state] || {}
      if (state.min && state.max) {
        setBounds([state.min, state.max])
        setTimeout(() => {
          setZoomedIn(true)
        }, 500)
      }

      if (zoomedIn) {
        setFeature(f)
      }
      const {cluster} = f.properties
      if (!cluster) return
      const clusterNumber = parseInt(cluster, 10)
      setSelectedCluster(clusterNumber)
    } else {
      setFeature({})
    }
  }

  function handleZoom(m, e) {
    const z = m.getZoom()
    if (z === zoom) return
    setZoom(z)
  }

  function handleMove(m) {
    const c = m.getCenter()
    if (c.lat === center.lat && c.lng === center.lng) return
    setCenter(c)
  }

  function ResetButton() {
    const {setSelectedCluster, setFeature} = useContext(SelectorContext)
    return (
      <button
        style={{
          position: 'absolute',
          bottom: '10px',
          right: '10px',
          zIndex: '1000',
          fontSize: '2.5em',
        }}
        onClick={() => {
          setSelectedCluster(0)
          setFeature({})
          setZoomedIn(false)
          setBounds([
            [-124.848974, 24.396308],
            [-66.885444, 49.384358],
          ])
        }}
        type="button"
      >
        <GrPowerReset />
      </button>
    )
  }

  function handleMouseMove(m, e) {
    if (zoomedIn) {
      const features = m.queryRenderedFeatures(e.point, {
        layers: [MAP_LAYER_ID],
      })
      if (features.length > 0) {
        const f = features[0]
        if (f.properties.cluster) {
          f.properties = transformProperties(features[0].properties)
          setHighlightedTract(f)
        } else {
          setHighlightedTract({})
        }
      } else {
        setHighlightedTract({})
      }
    }
    map && map.resize()
  }

  function handleMouseLeave(m) {
    setHighlightedTract({})
  }

  // HACK: There is a bug in react-mapbox-gl where the events are not set properly
  const handleClickRef = useRef(handleClick)
  const handleMouseMoveRef = useRef(handleMouseMove)
  const handleMouseLeaveRef = useRef(handleMouseLeave)
  const handleMoveRef = useRef(handleMove)
  const handleZoomRef = useRef(handleZoom)
  handleClickRef.current = handleClick
  handleMouseMoveRef.current = handleMouseMove
  handleMoveRef.current = handleMove
  handleZoomRef.current = handleZoom

  return (
    <Map
      // eslint-disable-next-line react/style-prop-object
      style={MAP_STYLE_URI}
      // center={center}
      // zoom={[zoom]}
      fitBounds={bounds}
      containerStyle={{
        height: width <= 540 ? '400px' : '100%',
        width: '100%',
      }}
      onZoomEnd={(m) => {
        handleZoomRef.current(m)
      }}
      onMoveEnd={(m) => {
        handleMoveRef.current(m)
      }}
      onClick={(m, e) => {
        handleClickRef.current(m, e)
      }}
      onTouchStart={(m, e) => {
        handleClickRef.current(m, e)
      }}
      onStyleLoad={(m) => {
        setMap(m)
      }}
      onSourceData={() => {
        setSourceDataLoaded(true)
      }}
      onMouseMove={(m, e) => {
        handleMouseMoveRef.current(m, e)
      }}
      onMouseLeave={(m) => {
        handleMouseLeaveRef.current(m)
      }}
    >
      <HighlightFeature feature={feature} />
      <HighlightFeature feature={highlightedTract} />

      {(selectedCluster > 0 || feature) && <ResetButton />}
      <ZoomControl />
    </Map>
  )
}

function HighlightFeature({feature}) {
  if (Object.keys(feature).length > 0 && feature.properties.cluster) {
    return (
      <GeoJSONLayer
        data={feature}
        linePaint={{
          'line-color': '#000',
          'line-width': 2,
        }}
      />
    )
  }
  return null
}
