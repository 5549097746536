import Flickity from 'react-flickity-component'
import 'flickity/dist/flickity.css'
import {useEffect, useRef} from 'react'

const flickityOptions = {
  prevNextButtons: false,
  reloadOnUpdate: true,
}

export default function ChartWrapper({
  width,
  children,
  openItemIndex,
  selectedCluster,
}) {
  const flickityRef = useRef(null)

  useEffect(() => {
    if (flickityRef.current) {
      // HACK: Flickity is resizing and repositioning one render late
      setTimeout(() => {
        flickityRef.current.reposition()
        flickityRef.current.resize()
      }, 1)
    }
  }, [flickityRef, width, openItemIndex, selectedCluster])

  if (width < 600) {
    return (
      <Flickity
        flickityRef={(c) => (flickityRef.current = c)}
        options={flickityOptions}
      >
        {children}
      </Flickity>
    )
  }

  return children
}
