const accordionSections = [
  {
    key: 'built',
    label: 'Built Capital',
    subGroups: [
      {
        label: 'Access to broadband internet',
        subLabel:
          '(share of households with broadband or cellular data subscription)',
        type: 'percent',
        key: 'pct_broadband',
        direction: 'desc',
      },
      {
        label: 'Access to domestic emergency operations centers',
        subLabel: '(distance in meters)',
        type: 'number',
        key: 'eoc_dist',
        unit: 'm',
        direction: 'asc',
      },
      {
        label: 'Access to fire stations or emergency medical services',
        subLabel: '(distance in meters)',
        type: 'number',
        key: 'fireems_dist',
        unit: 'm',
        direction: 'asc',
      },
      {
        label: 'Access to highways',
        subLabel: '(distance to highway exit in meters)',
        type: 'number',
        key: 'highway_dist',
        unit: 'm',
        direction: 'asc',
      },
      {
        label: 'Access to transportation stations',
        subLabel: '(distance in meters)',
        type: 'number',
        key: 'intermodal_dist',
        unit: 'm',
        direction: 'asc',
      },
      {
        label: 'Affordable-housing supply',
        subLabel: '(share of federally subsidized housing units)',
        type: 'percent',
        key: 'subsidizedproportion',
        direction: 'desc',
      },
      {
        label: 'Cell service coverage',
        subLabel: '(share of tract covered by cell service providers)',
        type: 'percent',
        key: 'cell',
        direction: 'desc',
      },
      {
        label: 'Housing quality',
        subLabel:
          '(share of housing units with kitchen and plumbing facilities)',
        type: 'percent',
        key: 'pct_kitchen',
        direction: 'desc',
      },
      {
        label: 'Workers with a short commute',
        subLabel: '(share with a commute of less than 30 minutes)',
        type: 'percent',
        key: 'pct_short_commute',
        direction: 'desc',
      },
    ],
  },
  {
    key: 'cultural',
    label: 'Cultural Capital',
    subGroups: [
      {
        key: 'distance_convention',
        label: 'Access to convention centers',
        subLabel: '(distance in meters)',
        type: 'number',
        unit: 'm',
        direction: 'asc',
      },
      {
        key: 'rev_artorg',
        label: 'Capacity of cultural organizations',
        subLabel: '(average annual revenue per 1,000 people)',
        type: 'number',
        unit: '$',
        direction: 'desc',
      },
      {
        key: 'cul_emp',
        label: 'Employment in cultural occupations',
        subLabel: '(share of workers)',
        type: 'percent',
        direction: 'desc',
      },
      {
        key: 'div_language',
        label: 'Language diversity',
        subLabel:
          '(Simpson’s Diversity Index; higher values mean greater diversity)',
        type: 'number',
        direction: 'desc',
      },
      {
        key: 'hist_prop',
        label: 'Presence of historic properties',
        subLabel: '(number of properties per 1,000 people)',
        type: 'number',
        direction: 'desc',
      },
      {
        key: 'div_race_ethnicity',
        label: 'Racial and ethnic diversity',
        subLabel:
          '(Simpson’s Diversity Index; higher values mean greater diversity)',
        type: 'number',
        direction: 'desc',
      },
      {
        key: 'div_religion',
        label: 'Religious diversity',
        subLabel:
          '(Simpson’s Diversity Index; higher values mean greater diversity)',
        type: 'number',
        direction: 'desc',
      },
    ],
  },
  {
    key: 'financial',
    label: 'Financial Capital',
    subGroups: [
      {
        key: 'distance_bank',
        label: 'Access to banks and credit unions',
        subLabel: '(distance in meters)',
        type: 'number',
        unit: 'm',
        direction: 'asc',
      },
      {
        key: 'deposit_per1k',
        label: 'Banking capacity',
        subLabel: '(total bank deposits per 1,000 people, in dollars)',
        type: 'number',
        unit: '$',
        direction: 'desc',
      },
      {
        key: 'cdfi_per1k',
        label: 'Community development financial institution investments',
        subLabel: '(dollars per 1,000 people)',
        type: 'number',
        unit: '$',
        direction: 'desc',
      },
      {
        key: 'fed_investments_percapita',
        label: 'Federal investment',
        subLabel: '(total dollars per 1,000 people)',
        type: 'number',
        unit: '$',
        direction: 'desc',
      },
      {
        key: 'median_housingvalue',
        label: 'Home value',
        subLabel: '(median home value in dollars)',
        type: 'number',
        unit: '$',
        direction: 'desc',
      },
      {
        key: 'median_income',
        label: 'Individual income',
        subLabel: '(median individual income in dollars)',
        type: 'number',
        unit: '$',
        direction: 'desc',
      },
    ],
  },
  {
    key: 'human',
    label: 'Human Capital',
    subGroups: [
      {
        key: 'childcare_access',
        label: 'Access to child care centers',
        subLabel: '(number per 1,000 children younger than 5)',
        type: 'number',
        direction: 'desc',
      },
      {
        key: 'college_distance',
        label: 'Access to college and university campuses',
        subLabel: '(distance in meters)',
        type: 'number',
        unit: 'm',
        direction: 'asc',
      },
      {
        key: 'farmersmarket_distance',
        label: "Access to farmers' markets",
        subLabel: '(distance in meters)',
        type: 'number',
        unit: 'm',
        direction: 'asc',
      },
      {
        key: 'hcfacilities_distance',
        label: 'Access to health care facilities',
        subLabel: '(distance in meters)',
        type: 'number',
        unit: 'm',
        direction: 'asc',
      },
      {
        key: 'healthprof_rate',
        label: 'Access to health care professionals',
        subLabel: '(number per 1,000 people)',
        type: 'number',
        direction: 'desc',
      },
      {
        key: 'educ_attainment',
        label: 'Educational attainment',
        subLabel: '(share with at least high school degree or equivalent)',
        type: 'percent',
        direction: 'desc',
      },
      {
        key: 'emp_pop_ratio',
        label: 'Employment to population ratio',
        subLabel: '(ratio of people employed to the tract population)',
        type: 'number',
        direction: 'desc',
      },
      {
        key: 'pct_insured',
        label: 'Health insurance coverage',
        subLabel: '(share with health insurance coverage)',
        type: 'percent',
        direction: 'desc',
      },
      {
        key: 'hospital_beds',
        label: 'Hospital capacity',
        subLabel: '(beds per 1,000 people)',
        type: 'number',
        direction: 'desc',
      },
      {
        key: 'labor_rate',
        label: 'Labor force participation',
        subLabel: '(share of 18- to 64-year-olds in the labor force)',
        type: 'percent',
        direction: 'desc',
      },
      {
        key: 'expectancy',
        label: 'Life expectancy',
        subLabel: '(age in years)',
        type: 'number',
        direction: 'desc',
      },
      {
        key: 'nursinghome_access',
        label: 'Nursing home capacity',
        subLabel: '(beds per 1,000 people 65 or older)',
        type: 'number',
        direction: 'desc',
      },
    ],
  },
  {
    key: 'natural',
    label: 'Natural Capital',
    subGroups: [
      {
        key: 'distance_coal',
        label: 'Access to coal mines',
        subLabel: '(distance in meters)',
        type: 'number',
        unit: 'm',
        direction: 'asc',
      },
      {
        key: 'distance_oilgas',
        label: 'Access to oil and natural gas',
        subLabel: '(distance in meters)',
        type: 'number',
        unit: 'm',
        direction: 'asc',
      },
      {
        key: 'avgpm25',
        label: 'Air quality',
        subLabel: '(average particulate matter)',
        type: 'number',
        direction: 'asc',
      },
      {
        key: 'cropproportionarea',
        label: 'Cropland coverage',
        subLabel: '(share of tract area)',
        type: 'percent',
        direction: 'desc',
      },
      {
        key: 'lake_river_coverage',
        label: 'Lake or river coverage',
        subLabel: '(share of tract area)',
        type: 'percent',
        direction: 'desc',
      },
      {
        key: 'easement_coverage',
        label: 'Land and natural resource protections',
        subLabel: '(share of tract area covered by conservation easement)',
        type: 'percent',
        direction: 'desc',
      },
      {
        key: 'park_coverage',
        label: 'Park coverage',
        subLabel: '(share of tract area)',
        type: 'percent',
        direction: 'desc',
      },
    ],
  },
  {
    key: 'political',
    label: 'Political Capital',
    subGroups: [
      {
        key: 'censuspart_2010',
        label: 'Census participation',
        subLabel: '(share of households)',
        type: 'percent',
        direction: 'desc',
      },
      {
        key: 'avgrev_501c4',
        label: 'Financial capacity of social welfare organizations',
        subLabel: '(average annual revenue per 1,000 people)',
        type: 'number',
        unit: '$',
        direction: 'desc',
      },
      {
        key: 'gov_emp',
        label: 'Government employment',
        subLabel: '(share employed by governmental institution)',
        type: 'percent',
        direction: 'desc',
      },
      {
        key: 'pol_competition',
        label: 'Political competition',
        subLabel: '(difference in share of Republican and Democratic votes)',
        type: 'number',
        direction: 'asc',
      },
      {
        key: 'voter_turnout',
        label: 'Voter participation',
        subLabel: '(share of eligible voters)',
        type: 'percent',
        direction: 'desc',
      },
    ],
  },
  {
    key: 'social',
    label: 'Social Capital',
    subGroups: [
      {
        key: 'social_per_1k',
        label: 'Access to civic and social organizations',
        subLabel: '(number per 1,000 people)',
        type: 'number',
        direction: 'desc',
      },
      {
        key: 'worship_per1k',
        label: 'Access to places of worship',
        subLabel: '(number per 1,000 people)',
        type: 'number',
        direction: 'desc',
      },
      {
        key: 'distance_library',
        label: 'Access to public libraries',
        subLabel: '(distance in meters)',
        type: 'number',
        unit: 'm',
        direction: 'asc',
      },
      {
        key: 'distance_school',
        label: 'Access to schools',
        subLabel: '(distance in meters)',
        type: 'number',
        unit: 'm',
        direction: 'asc',
      },
    ],
  },
]

export default accordionSections
