import React, {Component} from 'react'
import {Link} from 'react-router-dom'

export default class NotFound extends Component {
  render() {
    return (
      <div className="container">
        <div className="row mb-4">
          <div className="col-12">
            <h2>Oops!</h2>
            <p>This page doesn't exist.</p>
            <p>
              Not to worry, you can head back&nbsp;
              <Link to="/">home</Link>
              &nbsp;or select one of the sections above.
            </p>
          </div>
        </div>
      </div>
    )
  }
}
