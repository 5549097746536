import { FaEnvelope, FaFacebookF, FaRedditAlien, FaTwitter } from 'react-icons/fa'

export default function SocialButtons() {
  return (
    <div className="d-flex align-items-center justify-content-center mt-4 mb-8 mb-lg-7">
      <a
        href="https://twitter.com/intent/tweet?text=Reenvisioning%20rural%20America%3A%20How%20to%20invest%20in%20the%20strengths%20and%20potential%20of%20rural%20communities.%20(via%20%40urbaninstitute)%20https%3A%2F%2Furbn.is%2Frural"
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-dark rounded-circle lh-1 social-button mx-2"
      >
        <FaTwitter size='.75em' />
      </a>
      <a
        href="https://www.facebook.com/sharer/sharer.php?u=https%3A//urbn.is/rural"
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-dark rounded-circle lh-1 social-button mx-2"
      >
        <FaFacebookF size='.75em' />
      </a>
      <a
        href="https://reddit.com/submit?url=https://urbn.is/rural&title=Reenvisioning Rural America: How to Invest in the Strengths and Potential of Rural Communities"
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-dark rounded-circle lh-1 social-button mx-2"
      >
        <FaRedditAlien size='.75em' />
      </a>
      <a
        href="mailto:%20?Subject=New Urban Institute interactive&amp;Body=Hi! I thought you'd be interested in this new interactive from the Urban Institute: %22Reenvisioning Rural America: How to Invest in the Strengths and Potential of Rural Communities%22 https://urbn.is/rural"
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-dark rounded-circle lh-1 social-button mx-2"
      >
        <FaEnvelope size='.75em' />
      </a>
    </div>
  )
}
