import {createContext, useState} from 'react'

const SelectorContext = createContext({})

export default SelectorContext

export function SelectorContextProvider({children}) {
  const [selectedCluster, setSelectedCluster] = useState(0)
  const [feature, setFeature] = useState({})
  const [highlightedTract, setHighlightedTract] = useState({})

  return (
    <SelectorContext.Provider
      value={{
        selectedCluster,
        setSelectedCluster,
        feature,
        setFeature,
        highlightedTract,
        setHighlightedTract,
      }}
    >
      {children}
    </SelectorContext.Provider>
  )
}
