import {useEffect, useState} from 'react'
import accordionSections from '../constants/accordionSections'
import AccordionItem from './AccordionItem'
import {kebabCase} from 'lodash'
import {OrderByContextProvider} from '../utilities/OrderByContext'
import useRouter from '../utilities/useRouter'

export default function Accordion() {
  const [openItemIndex, setOpenItemIndex] = useState(null)
  const router = useRouter()
  // Using the hashes to get the index value
  const accordionSectionHashes = accordionSections.map(
    (group) => '#' + kebabCase(group.label),
  )
  useEffect(() => {
    const hash = router.location.hash
    if (accordionSectionHashes.includes(hash)) {
      const index = accordionSectionHashes.indexOf(hash)
      setOpenItemIndex(index, accordionSections[index].label)
      const element = document.getElementById(hash.substring(1))
      // HACK: Using the set timout to ensure the state is updated before scrolling
      // Seems like we need atleast a 200ms delay
      setTimeout(() => {
        const y = element.getBoundingClientRect().top + window.pageYOffset
        window.scrollTo({top: y, behavior: 'smooth'})
      }, 100)
    } else {
      setOpenItemIndex(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function openItem(itemIndex, label) {
    setOpenItemIndex(itemIndex)
    // HACK: Using the set timout to ensure the state is updated before scrolling
    // Seems like we need atleast a 100ms delay for this
    const kebabLabel = kebabCase(label)
    window.location.hash = '#' + kebabLabel
    const element = document.getElementById(kebabLabel)
    setTimeout(() => {
      const y = element.getBoundingClientRect().top + window.pageYOffset
      window.scrollTo({top: y, behavior: 'smooth'})
    }, 100)
  }

  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="row my-4">
            <div className="accordion accordion-flush" id="group-accordion">
              <OrderByContextProvider>
                {accordionSections.map((group, i) => (
                  <AccordionItem
                    key={i}
                    group={group}
                    openItemIndex={openItemIndex}
                    openItem={openItem}
                    itemIndex={i}
                  />
                ))}
              </OrderByContextProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
