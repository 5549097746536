import {isNumber, map, round, startCase} from 'lodash'

const details = {
  tractpopulation1418: {
    label: 'Population',
  },
  landareasquaremiles2010: {
    label: 'Land area',
    unit: 'square miles',
  },
  muninum: {
    label: 'Number of municipalities',
  },
  ctyauth: {
    label: 'Degree of county independence from state',
  },
  fedinvest: {
    label: 'Recent experience with federal investment',
  },
  execelect: {
    label: 'Elected executive',
  },
  rigoexist: {
    label: 'Regional government network',
  },
  regcommission: {
    label: 'Regional commission service area',
  },
  triballands: {
    label: 'Tribal lands',
  },
  unitedway: {
    label: 'United Way',
  },
  chamber: {
    label: 'Chamber of Commerce',
  },
  chambernum: {
    label: 'Number of chambers of commerce',
  },
}
export default function DetailList({feature}) {
  return (
    <ul className="small ps-3 mb-4">
      {map(details, (detail, key) => (
        <DetailListItem
          key={key}
          detailData={feature[key]}
          detail={detail}
          detailKey={key}
        />
      ))}
    </ul>
  )
}

const regCommisssionMap = {
  ARC: 'Appalachian',
  DRA: 'Delta',
  NBRC: 'Northern Border',
  None: 'None',
}

function DetailListItem({detail, detailKey, detailData}) {
  let value = detailData
  if (detailKey === 'landareasquaremiles2010') {
    if (value === 0) {
      value = '< 0.5'
    } else {
      value = value.toLocaleString()
    }
  }
  if (typeof value === 'undefined' || value.length === 0) {
    return null
  }
  if (isNumber(value) && !Number.isInteger(value)) {
    value = round(value, 2)
    value = value.toLocaleString()
  }
  if (detailKey === 'tractpopulation1418') {
    value = value.toLocaleString()
  }
  if (
    ['triballands', 'execelect', 'fedinvest', 'rigoexist'].includes(detailKey)
  ) {
    value = startCase(value)
  }
  if (detailKey === 'triballands' || detailKey === 'rigoexist') {
    if (value === 'No') {
      value = 'None'
    }
  }
  if (detailKey === 'regcommission') {
    value = regCommisssionMap[detailData]
  }
  if (detailKey === 'unitedway') {
    if (value === '0') {
      return null
    }
    if (value === '1') {
      value = 'Yes'
    }
  }
  if (detailKey === 'execelect') {
    if (value === 'No') return null
  }
  if (detailKey === 'chamber') {
    if (value === '0') {
      value = 'No'
    } else {
      value = 'Yes'
    }
  }
  return (
    <li>
      {detail.label}:{' '}
      <span className="fw-bold fst-italic">
        {value} {detail.unit && detail.unit}
      </span>
    </li>
  )
}
