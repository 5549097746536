import App from './App'
import NotFound from './pages/NotFound'
import Home from './pages/Home'

const routes = [
  {
    component: App,
    routes: [
      {
        path: '/',
        component: Home,
        exact: true,
      },
      {
        path: '*',
        component: NotFound,
      },
    ],
  },
]

export default routes
