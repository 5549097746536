import {Fragment} from 'react'
import Slider from 'react-slick'

import built from './icons/built.svg'
import cultural from './icons/cultural.svg'
import financial from './icons/financial.svg'
import human from './icons/human.svg'
import natural from './icons/natural.svg'
import political from './icons/political.svg'
import social from './icons/social.svg'

const peerContent = [
  {
    name: 'Built',
    description:
      '“Infrastructure” casts a wide net, encompassing housing, transportation and communication systems, and anything else constructed by people to support society. To summarize a tract’s built capital, we analyzed commute times, affordable-housing supply,  cell service coverage, and access to broadband internet, fire stations, highways, transportation, and emergency response centers.',
    icon: built,
  },
  {
    name: 'Cultural',
    description:
      'Culture encompasses the fabric of community life, including the people who live in a region, the languages they speak, and the institutions they support. We measured a tract’s cultural capital through its diversity of languages, religions, races, and ethnicities and its cultural institutions, including historic properties, cultural organizations and occupations, and convention centers.',
    icon: cultural,
  },
  {
    name: 'Financial',
    description:
      'A region’s financial stability depends on the wealth of its people, the availability of financial resources, and the strength of its financial organizations. To measure financial capital, we considered income, home value, banking capacity and access, and federal and community development investments.',
    icon: financial,
  },
  {
    name: 'Human',
    description:
      'People need access to institutions that promote their health, support their education and training, and provide them with a livelihood. We measured a tract’s human capital through that lens, focusing on employment opportunities and access to and the capacity of health care and educational systems.',
    icon: human,
  },
  {
    name: 'Natural',
    description:
      'Communities are often considered in relation to their built environment, but the natural environment also shapes a region’s identity, industry, and culture. To measure an area’s natural capital, we considered its air quality; its protections for natural resources and land; its land coverage in crops, parks, and water; and its access to extractive resources, such as oil, coal, and natural gas.',
    icon: natural,
  },
  {
    name: 'Political',
    description:
      'Small or large, every community has governments that shape its priorities and future. We measure a region’s political capital based on the region’s influence over policy: through government employment, political competition, and social welfare organizations and the degree to which its population participates in elections and the census.',
    icon: political,
  },
  {
    name: 'Social',
    description:
      'If cultural capital represents the fabric of community life, social capital represents the stitches holding the fabric together. Social capital encourages economic growth that benefits an entire community, so we measure it through the access a region has to organizations that encourage interconnectedness, including places of worship, public libraries, schools, and civic and social-minded organizations.',
    icon: social,
  },
]

let mappedContent = peerContent.map(function (group, i) {
  return (
    <div
      key={i}
      className="d-flex flex-column flex-md-row justify-content-center align-items-center mb-3"
    >
      <span
        className="align-self-center p-2 me-md-4 fs-1"
        style={{lineHeight: 0}}
      >
        <img src={group.icon} alt="Group icon" />
      </span>
      <div className="d-flex flex-column">
        <h2 className="fs-5 fw-bold text-center text-md-start my-2">
          {group.name}
        </h2>
        <p className="small text-center text-md-start">{group.description}</p>
      </div>
    </div>
  )
})

export default function PeerExplainers() {
  return (
    <Fragment>
      <Slider
        dots
        adaptiveHeight
        arrows={false}
        className="mb-5 mt-2 d-block d-md-none"
      >
        {mappedContent}
      </Slider>

      <div className="d-none d-md-flex row justify-content-center my-5 my-xl-7">
        <div className="col-12 col-md-10 col-lg-8 col-xl-7">
          {mappedContent}
        </div>
      </div>
    </Fragment>
  )
}
